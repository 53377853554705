require('dotenv').config()

export const store = {
  data: [],
  token: null,
  localStorageKey: 'avis',
}

export const mutations = {
  setData(value) {
    store.data = value;
  },
  setToken(value) {
    store.token = value;
  },
};

export default store