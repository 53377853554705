import Vue from "vue";

export const store = Vue.observable({
  isNavOpen: false,
  listsArticles: [],
  altert: null,
  banner:
    {
      link: null,
      wordingCTA: null,
      content: null
    },
  api: {
    access_token: process.env.VUE_APP_API_TOKEN,
    base: process.env.VUE_APP_API_URL
  },
});

export const mutations = {
  toggleNav() {
    store.isNavOpen = !store.isNavOpen;
  },
  clossNav() {
    store.isNavOpen = false;
  },
  setListsArticles(listsArticles) {
    store.listsArticles = listsArticles;
  },
};

export default store
